<template>
    <div class="stage">
        <div class="nav">
            <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect">
                <el-submenu index="1">
                    <template slot="title">全景楼书</template>
                    <el-menu-item index="1-1">全部</el-menu-item>
                    <el-menu-item index="1-2">我创建的</el-menu-item>
                    <el-menu-item index="1-3">团队创建</el-menu-item>
                    <el-menu-item index="1-4">自助创建</el-menu-item>
                </el-submenu>
                <el-menu-item index="2">预约登记</el-menu-item>
                <el-menu-item index="3">电话记录</el-menu-item>
                <el-menu-item index="4">业务统计</el-menu-item>
            </el-menu>
            <el-date-picker v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="handleChangeTimePicker">
            </el-date-picker>
        </div>
        <div class="num">查询到{{count}}条数据</div>
        <el-table :data="tableData" border style="width: 100%" v-loading="loading" v-if="label == 'panoramaBook'"
            key='1' :height="tableHeight" ref="table" :header-cell-style="{background:'#333',color:'#fff'}">
            <el-table-column fixed prop="title" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="created_time" label="创建时间" align="center">
            </el-table-column>
            <el-table-column prop="username" label="创建者" align="center">
            </el-table-column>
            <el-table-column prop="visitorNum" label="访客" align="center">
            </el-table-column>
            <el-table-column prop="stopTime" label="累计停留" align="center">
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看报告</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-table :data="tableData" border style="width: 100%" v-loading="loading" v-if="label == 'appointment'" key='2'
            :height="tableHeight" ref="table" :header-cell-style="{background:'#333',color:'#fff'}">
            <el-table-column fixed prop="date_of_visit_time" label="预约时间" align="center">
            </el-table-column>
            <el-table-column prop="real_name" label="接待人员" align="center">
            </el-table-column>
            <el-table-column prop="appointment_type" label="客户类型" align="center">
            </el-table-column>
            <el-table-column prop="company_name" label="公司名称" align="center">
            </el-table-column>
            <el-table-column prop="phone" label="联系方式" align="center">
            </el-table-column>
            <el-table-column prop="created_time" label="创建时间" align="center">
            </el-table-column>
        </el-table>
        <el-table :data="tableData" border style="width: 100%" v-loading="loading" v-if="label == 'phoneRecord'" key='3'
            :height="tableHeight" ref="table" :header-cell-style="{background:'#333',color:'#fff'}">
            <el-table-column fixed prop="created_time" label="拨打时间" align="center">
            </el-table-column>
            <el-table-column prop="real_name" label="接听电话" align="center">
            </el-table-column>
            <el-table-column prop="phone" label="来电号码" align="center">
            </el-table-column>
        </el-table>
        <el-table :data="tableData" border style="width: 100%" v-loading="loading" v-if="label == 'BusinessStatistics'"
            key='4' :height="tableHeight" ref="table" :header-cell-style="{background:'#333',color:'#fff'}">
            <el-table-column fixed prop="real_name" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="bookNum" label="创建楼书" align="center">
            </el-table-column>
            <el-table-column prop="visitorNum" label="带来访客" align="center">
            </el-table-column>
            <el-table-column prop="phoneNum" label="接听来电" align="center">
            </el-table-column>
            <el-table-column prop="appointmentNum" label="接受预约" align="center">
            </el-table-column>
        </el-table>
        <el-dialog title="" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
            <div class="list">
                <div class="title">访客清单</div>
                <el-table :data="listVisitor" height="250" border style="width: 100%" :header-cell-style="{background:'#f1f1f1'}">
                    <el-table-column prop="name" label="昵称" align="center">
                    </el-table-column>
                    <el-table-column prop="visitorNum" label="访问次数" align="center">
                    </el-table-column>
                    <el-table-column prop="stopTime" label="停留时间" align="center">
                    </el-table-column>
                </el-table>
            </div>
            <div class="list">
                <div class="title">查看空间</div>
                <el-table :data="space" height="250" border style="width: 100%" :header-cell-style="{background:'#f1f1f1'}">
                    <el-table-column prop="name" label="空间" align="center">
                    </el-table-column>
                    <el-table-column prop="visitorNum" label="访问次数" align="center">
                    </el-table-column>
                    <el-table-column prop="stopTime" label="停留时间" align="center">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: 'manage-stage',
        data() {
            return {
                label: 'panoramaBook',//panoramaBook全景楼书appointment预约记录phoneRecord电话记录BusinessStatistics业务统计），start（开始时间），end（结束时间）,
                tableData: [],
                activeIndex: '1-1',
                time: '',
                type: 0,//1 我创建 2 团队 3 自主
                count: 0,
                loading: false,
                start: '',
                end: '',
                tableHeight: 'calc(100vh - 300px)',
                dialogVisible: false,
                listVisitor: [],
                space: []
            }
        },
        methods: {
            getData() {
                this.loading = true
                this.$axios({
                    method: 'POST',
                    url: 'api/v5/panorama/admin/workbench',
                    data: {
                        label: this.label,
                        start: this.start,
                        end: this.end,
                        type: this.type
                    },
                    headers: {
                        "panorama-api-token":sessionStorage.getItem('token')
                        // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                    }
                }).then(res => {
                    if(res.data.status == '200'){
                        this.loading = false
                        this.count = res.data.data.count
                        this.tableData = res.data.data.data
                        let that = this
                        if (that.$refs.table) {
                            that.$nextTick(() => {
                                that.$refs.table.doLayout()
                            })
                        }
                    }else if(res.data.status == '1001'){
                        this.$router.push({
                            path: "/login",
                            query: {
                                resource:'index'
                            }
                        })
                    }
                    
                })
            },
            handleSelect(index) {
                this.activeIndex = index
                if (index == '1-1' || index == '1-3' || index == '1-2' || index == '1-0') {
                    this.label = 'panoramaBook'
                    if (index == '1-2') {
                        this.type = 1
                    } else if (index == '1-3') {
                        this.type = 2
                    } else if (index == '1-4') {
                        this.type = 3
                    } else {
                        this.type = 0
                    }
                } else if (index == '2') {
                    this.label = 'appointment'
                    this.type = ''
                } else if (index == '3') {
                    this.label = 'phoneRecord'
                    this.type = ''
                } else {
                    this.label = 'BusinessStatistics'
                    this.type = ''
                }
                this.getData()
            },
            handleClick(r) {
                this.$axios({
                    method: 'post',
                    url: 'api/v5/panorama/admin/workbench/panoramaData',
                    data: {
                        panorama_id: r.id
                    },
                    headers: {
                        "panorama-api-token":sessionStorage.getItem('token')
                        // "panorama-api-token": 'e6965432851d96da99c91138a21f3088'
                    }
                }).then(res => {
                    this.dialogVisible = true
                    console.log(res)
                    this.space = res.data.data.space
                    this.listVisitor = res.data.data.listVisitor
                })
            },
            handleChangeTimePicker(t) {
                if (t) {
                    this.start = t[0];
                    this.end = t[1];
                } else {
                    this.start = ''
                    this.end = ''
                }
                this.getData()

            },
            handleClose() {
                this.dialogVisible = false
            }
        },
        created() {
            // this.tableHeight = document.body.offsetHeight - 370
            this.getData()
        }
    }
</script>
<style>
    .stage .el-menu {
        width: 50%;
        border: none;
    }
    .stage .el-menu-item, .stage .el-submenu__title{
        font-size:20px;
    }
</style>
<style lang='scss' scoped>
    .stage {
        .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
        }

        .num {
            margin: 20px 0;
            font-size: 18px;
            color: #666;
        }
        .title{
            margin:12px 0;
            font-size:20px;
            font-weight:bold;
        }
    }
</style>